import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import Dropdown from "rc-dropdown";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Settings = ({
  theme,
  selectedTheme,
  setTheme,
  deleteUserHandler,
  deleteAllChatHandler,
  handleClose,
}) => {
  const { currentUser } = useSelector((state) => state.auth);

  const [selectedMenu, setSelectedMenu] = useState("general");
  const navigate = useNavigate();

  return (
    <div
      className={`pt-8 px-6 ${theme === "dark" ? "bg-black-100" : "bg-white"}`}
    >
      <div className="flex flex-row justify-between border-b border-white border-opacity-20 pb-4">
        <div className="text-2xl font-bold">Settings</div>
        <IoCloseCircleOutline
          color={theme === "dark" ? "white" : "black"}
          size={24}
          className="cursor-pointer"
          onClick={handleClose}
        />
      </div>
      <div className="grid grid-cols-3 mt-4 pb-10 gap-4">
        <div className="col-span-1">
          <div
            className={`flex flex-row h-10 items-center  rounded-lg cursor-pointer px-3 mb-1.5
            ${
              theme === "dark"
                ? selectedMenu === "general"
                  ? "bg-black-200"
                  : "bg-black-100"
                : selectedMenu === "general"
                ? "bg-neutral-50"
                : "bg-white"
            }
            `}
            onClick={() => {
              setSelectedMenu("general");
            }}
          >
            <IoMdSettings
              color={theme === "dark" ? "white" : "black"}
              className="opacity-70 w-6 h-6 pr-2"
            />
            <div
              className={`${
                theme === "dark" ? "text-white" : "text-black-100"
              } opacity-70 text-[15px] `}
            >
              General
            </div>
          </div>

          <div
            className={`flex flex-row h-10 items-center  rounded-lg cursor-pointer px-3 mb-1.5
              ${
                theme === "dark"
                  ? selectedMenu === "builder"
                    ? "bg-black-200"
                    : "bg-black-100"
                  : selectedMenu === "builder"
                  ? "bg-neutral-50"
                  : "bg-white"
              }
              `}
            onClick={() => {
              setSelectedMenu("builder");
            }}
          >
            <FaRegUser
              color={theme === "dark" ? "white" : "black"}
              className="opacity-70 w-6 h-6 pr-2"
            />

            <div
              className={`${
                theme === "dark" ? "text-white" : "text-black-100"
              } opacity-70 text-[15px] `}
            >
              Builder Profile
            </div>
          </div>
        </div>
        <div className="col-span-2">
          {selectedMenu === "general" ? (
            <div>
              <div className="py-4 border-b border-white border-opacity-20 flex flex-row justify-between">
                <div
                  className={`opacity-70 ${
                    theme === "dark" ? "text-white" : "text-black-100"
                  }`}
                >
                  Theme
                </div>
                <Dropdown
                  trigger={["click"]}
                  align={{ offset: [-20, 0] }}
                  overlay={
                    <div
                      className={`${
                        theme === "dark" ? "bg-black-200" : "bg-white border"
                      } rounded-lg w-[140%]`}
                    >
                      <div
                        className={`${
                          theme === "dark"
                            ? "text-white hover:bg-black-50"
                            : "text-black-100 hover:bg-neutral-50"
                        } opacity-70 py-2  cursor-pointer  px-3 `}
                        onClick={() => {
                          localStorage.setItem("theme", "dark");
                          setTheme("Dark");
                        }}
                      >
                        Dark
                      </div>
                      <div
                        className={`${
                          theme === "dark"
                            ? "text-white hover:bg-black-50"
                            : "text-black-100 hover:bg-neutral-50"
                        } opacity-70 py-2  cursor-pointer  px-3 rounded-b-lg`}
                        onClick={() => {
                          localStorage.setItem("theme", "Light");
                          setTheme("Light");
                        }}
                      >
                        Light
                      </div>
                    </div>
                  }
                >
                  <div className="flex flex-row gap-2 cursor-pointer">
                    <div
                      className={`${
                        theme === "dark" ? "text-white" : "text-black-100"
                      } opacity-70`}
                    >
                      {selectedTheme}
                    </div>
                    <FaChevronDown
                      color={theme === "dark" ? "white" : "black"}
                      size="16"
                      className="mt-1"
                    />
                  </div>
                </Dropdown>
              </div>
              <div className="py-4 border-b border-white border-opacity-20 flex flex-row justify-between">
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 flex flex-col justify-center`}
                >
                  Delete All Chats
                </div>
                <button
                  className="bg-red-100 font-sans text-xs outline-none borer border-red-100 flex flex-row items-center justify-center 
            text-white  rounded-full py-2 px-3 
            "
                  onClick={deleteAllChatHandler}
                >
                  Delete all
                </button>
              </div>
              <div className="py-4  flex flex-row justify-between">
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 flex flex-col justify-center`}
                >
                  Delete Account
                </div>
                <button
                  className="bg-red-100 font-sans text-xs outline-none borer border-red-100 flex flex-row items-center justify-center 
            text-white  rounded-full py-2 px-5 
            "
                  onClick={deleteUserHandler}
                >
                  Delete
                </button>
              </div>
            </div>
          ) : selectedMenu === "builder" ? (
            <div>
              <div className="py-4 border-b border-white border-opacity-20 flex flex-row justify-between">
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 flex flex-col justify-center`}
                >
                  Name
                </div>
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 `}
                >
                  {currentUser && currentUser.name ? currentUser.name : "-"}
                </div>
              </div>
              <div className="py-4  flex flex-row justify-between gap-3">
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 flex flex-col justify-center`}
                >
                  Email
                </div>
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 `}
                >
                  {currentUser && currentUser.email ? currentUser.email : "-"}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Settings;
